var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.systemComponentsData)?_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("#")]),_c('th',{attrs:{"colspan":"3"}},[_vm._v(_vm._s(_vm.$t("SystemComponents.data")))]),_vm._m(0)]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("general.image")))]),_c('th',[_vm._v(_vm._s(_vm.$t("general.code")))]),_c('th',{staticClass:"cell-lg"},[_vm._v(_vm._s(_vm.$t("general.name")))])])]),_c('tbody',_vm._l((_vm.systemComponentsData),function(systemComponent,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(++index + _vm.filterData.currentIndex))]),_c('td',[_c('img',{staticClass:"item-img-table",attrs:{"src":_vm.fullPathFileFromServer(
                systemComponent.systemComponentImagePath,
                _vm.defaultImg
              ),"onerror":("this.src='" + _vm.defaultImg + "'")}})]),_c('td',[_vm._v(_vm._s(_vm.isDataExist(systemComponent.fullCode)))]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(systemComponent.systemComponentNameCurrent))+" ")]),_c('td',{staticClass:"fmenu-item-container"},[_c('FloatingMenu',[_c('li',[_c('button',{attrs:{"title":_vm.$t('info')},on:{"click":function($event){_vm.setSystemComponentData(systemComponent);
                  _vm.openBottomSheet('SystemComponentInfo');}}},[_c('img',{attrs:{"src":require("@/assets/images/info.svg")}})])]),_c('li',[_c('button',{attrs:{"title":_vm.$t('general.qrCode')},on:{"click":function($event){_vm.setSystemComponentData(systemComponent);
                  _vm.openBottomSheet('SystemComponentQRCode');}}},[_c('img',{attrs:{"src":require("@/assets/images/qr-code.svg")}})])]),(_vm.hasPrivilegeEdit)?_c('li',[_c('button',{attrs:{"title":_vm.$t('edit')},on:{"click":function($event){_vm.setSystemComponentData(systemComponent);
                  _vm.openBottomSheet('SystemComponentUpdate');}}},[_c('img',{attrs:{"src":require("@/assets/images/pencil.svg")}})])]):_vm._e(),(_vm.hasPrivilegeFinaleDelete)?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.SystemComponentDelete",modifiers:{"SystemComponentDelete":true}}],attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.setSystemComponentData(systemComponent)}}},[_c('img',{attrs:{"src":require("@/assets/images/trash.svg")}})])]):_vm._e(),(_vm.hasPrivilegeChangeActivationType)?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.SystemComponentChangeActivationType",modifiers:{"SystemComponentChangeActivationType":true}}],attrs:{"title":_vm.$t('changeActivationType')},on:{"click":function($event){return _vm.setSystemComponentData(systemComponent)}}},[_c('img',{attrs:{"src":require("@/assets/images/changeActivationType.svg")}})])]):_vm._e(),_c('li',[_c('button',{attrs:{"title":_vm.$t('actionsData.modelName')},on:{"click":function($event){_vm.setSystemComponentData(systemComponent);
                  _vm.openBottomSheet('ActionsData');}}},[_c('img',{attrs:{"src":require("@/assets/images/actions-data.svg")}})])])])],1)])}),0)])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',{attrs:{"rowspan":"2"}},[_c('i',{staticClass:"fas fa-sliders-h"})])}]

export { render, staticRenderFns }